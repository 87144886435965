export const defaultBuildingsQuery = {
	_limit: 25,
	_sortColumn: 'address',
	_sortDirection: 'asc',
	mapAreaType: 'city',
};

export const buildingsInclude = 'clients';

export const perPageVals = [5, 15, 25, 30, 60, 100, 200];

export const sortOptions = [
	{value: 'address', title: 'Address'},
	{value: 'manufacturingYear', title: 'Manufacturing year'},
	{value: 'zip', title: 'ZIP code'},
	{value: 'city', title: 'Municipality'},
];

export const buildingsFiltersKeys = [
	'encounterState',
	'dateFrom',
	'dateTo',
	'manufacturingYearStart',
	'manufacturingYearEnd',
	'unknownManufacturingYear',
	'onlyWithClientWithPhone',
	'onlyUnknownBuildings',
	'zip',
	'city',
	'municipalName',
	'_q',
	'areas',
	'tagIds',
	'uniqId',
	'types',
	'latestEncounterType',
	'latestEncounterState',
	'latestEncounterReasonMappingId',
	'drawnPolygon',
	'products',
	'buildingProductStateEncounterStates',
];

export const emptyBuildingsFilters = {
	encounterState: '',
	dateFrom: null,
	dateTo: null,
	manufacturingYearStart: null,
	manufacturingYearEnd: null,
	unknownManufacturingYear: false,
	onlyWithClientWithPhone: false,
	onlyUnknownBuildings: false,
	zip: null,
	city: '',
	municipalName: '',
	q: '',
	areas: [],
	types: '',
	latestEncounterType: '',
	latestEncounterState: '',
	latestEncounterReasonMappingId: '',
	// Stores array values as comma separated string (not the most optimal way to do it BUT it saves us trouble of formatting/parsing input to/from api)
	tagIds: '',
	uniqId: null,
	drawnPolygon: '',
	products: [],
	buildingProductStateEncounterStates: '',
};

export const massSelectionLimit = 15000;

export const csvUniqIdStorageKey = 'buildings.buildings.csvUniqId';
