import cookies from 'js-cookie';
import {apiUrl} from 'constants/app';
import {shake, defaultPredicate} from 'utils/shake';

const shakeParamsPredicate = value =>
	Array.isArray(value) ? !!value.length : defaultPredicate(value);

export const useApi = () => {
	const createHeaders = headers => {
		const apiToken = cookies.get('api_token');
		const organizationId = cookies.get('organization_id');

		return new Headers({
			'Content-Type': 'application/json; charset=utf-8',
			...(apiToken ? {Authorization: `Bearer ${apiToken}`} : {}),
			...(organizationId ? {'organization-id': organizationId} : {}),
			...headers,
		});
	};

	const createRequest = method => (path, options) => {
		const url = new URL(`${apiUrl}${path}`);
		const {params, body, ...opts} = options;

		if (params) {
			url.search = new URLSearchParams(shake(params, shakeParamsPredicate)).toString();
		}

		return new Request(url, {
			...opts,
			body: body ? JSON.stringify(body) : undefined,
			method,
			headers: createHeaders(options.headers || {}),
		});
	};

	const handleResponse = resp => {
		if (!resp.ok) {
			throw resp;
		}
		return resp.json();
	};

	return {
		get: (url, options = {}) =>
			fetch(createRequest('GET')(url, options)).then(handleResponse),
		put: (url, options = {}) =>
			fetch(createRequest('PUT')(url, options)).then(handleResponse),
		post: (url, options = {}) =>
			fetch(createRequest('POST')(url, options)).then(handleResponse),
		remove: (url, options = {}) =>
			fetch(createRequest('DELETE')(url, options)).then(handleResponse),
	};
};
