import React, {useEffect} from 'react';
import {useQueryTeamIndex} from 'hooks/api/team/useQueryTeam';
import {UsersRound} from 'lucide-react';

const TeamPicker = ({teamId, onTeamIdChange}) => {
	const {data: teamsData} = useQueryTeamIndex({
		params: {
			include: 'users',
			getAllTeams: false,
			type: 'sales',
		},
	});

	useEffect(() => {
		if (teamsData?.data?.length) {
			onTeamIdChange(teamsData?.data[0]?.id);
		}
	}, [teamsData, onTeamIdChange]);

	return (
		<div className="flex gap-3 items-center">
			<UsersRound className="size-5 text-gray-400" />
			<select
				value={teamId || ''}
				onChange={e => onTeamIdChange(e.target.value || null)}
				className="border rounded-md h-8 px-2 font-medium shadow-sm"
			>
				<option value="">-</option>
				{(teamsData?.data || []).map(team => (
					<option key={team.id} value={team.id}>
						{team.title}
					</option>
				))}
			</select>
		</div>
	);
};

export default TeamPicker;
