import React, {useState, useMemo, useEffect} from 'react';
import {TeamTargetsForm} from './TeamTargetsForm';
import {useQueryTargetIndex} from 'hooks/api/target/useQueryTarget';
import {FormattedNumber, FormattedMessage} from '@meiko/react-intl';
import {useQueryUserIndex} from 'hooks/api/user/useQueryUser';
import {currencyOptions} from './utils';
import {getYear, getMonth} from 'date-fns';
import {Loader2} from 'lucide-react';
import MonthPicker from './MonthPicker';
import TeamPicker from './TeamPicker';
import {useAuth} from 'hooks/useAuth';

export const TeamTargets = ({
	prevTeamId = null,
	prevDate = null,
	onTeamChange,
	onDateChange,
}) => {
	const {user} = useAuth();
	const canShowBudget = !!user?.permissions.find(p => p.slug === 'budget.show');

	const [teamId, setTeamId] = useState(prevTeamId);
	const [date, setDate] = useState(prevDate);

	const year = date ? getYear(new Date(date)) : null;
	const month = date ? getMonth(new Date(date)) + 1 : null;

	useEffect(() => {
		if (teamId !== prevTeamId) {
			onTeamChange(teamId);
		}
	}, [teamId, onTeamChange, prevTeamId]);

	useEffect(() => {
		if (date !== prevDate) {
			onDateChange(date);
		}
	}, [date, onDateChange, prevDate]);

	const {data: usersData, isFetching: isUsersDataFetching} = useQueryUserIndex({
		params: {
			teamId,
		},
		useQueryOptions: {
			enabled: !!teamId,
		},
	});

	const {data: targetData, isFetching: isTargetDataFetching} = useQueryTargetIndex({
		params: {
			year,
			month,
			getFullWeeks: true,
			timeSpan: 'week',
			userIds: usersData?.data?.map(({id}) => id),
		},
		useQueryOptions: {
			enabled: !!date && !!teamId,
		},
	});

	const {data: monthBudgetData, isFetching: isMonthBudgetDataFetching} =
		useQueryTargetIndex({
			params: {
				year,
				month,
				timeSpan: 'month',
				type: 'dashboard-sales-budget-sum',
				teamId,
			},
			useQueryOptions: {
				enabled: !!date && !!teamId && canShowBudget,
			},
		});

	const budgetSum = monthBudgetData?.data?.[0]?.target;

	const monthlySalesTargetSum = targetData?.data
		?.filter(t => t.type === 'dashboard-sales-sum')
		.reduce((acc, t) => acc + t.target, 0);

	const budgetDiff = useMemo(
		() => (monthlySalesTargetSum || 0) - (budgetSum || 0),
		[budgetSum, monthlySalesTargetSum],
	);

	const isBudgetDiffVisible = useMemo(
		() => canShowBudget && !isTargetDataFetching && budgetDiff !== 0,
		[budgetDiff, canShowBudget, isTargetDataFetching],
	);

	const isPending = useMemo(() => {
		return isTargetDataFetching || isUsersDataFetching;
	}, [isTargetDataFetching, isUsersDataFetching]);

	return (
		<div className="flex flex-col gap-6">
			<div className="flex justify-between items-start gap-8">
				<div className="flex flex-col gap-2 pl-4 border-r pr-8">
					<MonthPicker month={date} onMonthChange={setDate} />
					<TeamPicker teamId={teamId} onTeamIdChange={setTeamId} />
				</div>

				<div className="flex justify-end items-start gap-6">
					{canShowBudget && (
						<div className="bg-amber-100 text-yellow-700 font-medium px-4 py-2 flex flex-col gap-1 rounded-lg border border-amber-200 min-w-32">
							<span>
								<FormattedMessage id="Budget" />
							</span>
							<span className="flex items-center gap-1">
								{isMonthBudgetDataFetching ? (
									<Loader2 className="size-4 animate-spin my-1" />
								) : (
									<span className="text-gray-700 text-base">
										{budgetSum ? (
											<FormattedNumber value={budgetSum} {...currencyOptions} />
										) : (
											'-'
										)}
									</span>
								)}
							</span>
						</div>
					)}
					<div className="bg-teal-50 text-teal-700 font-medium px-4 py-2 flex flex-col gap-1 rounded-lg border border-teal-100 min-w-32">
						<span>
							<FormattedMessage id="Monthly target sum" />
						</span>
						<div className="flex items-center gap-1.5">
							{isTargetDataFetching ? (
								<Loader2 className="size-4 animate-spin my-1" />
							) : (
								<span className="text-base text-gray-700">
									{monthlySalesTargetSum ? (
										<FormattedNumber value={monthlySalesTargetSum} {...currencyOptions} />
									) : (
										'-'
									)}
								</span>
							)}
							{isBudgetDiffVisible && (
								<span className="text-sm text-gray-700/50">
									(<FormattedNumber value={budgetDiff} {...currencyOptions} />)
								</span>
							)}
						</div>
					</div>
				</div>
			</div>

			<TeamTargetsForm
				teamId={teamId}
				date={date}
				targets={targetData?.data}
				users={usersData?.data}
				isPending={isPending}
			/>
		</div>
	);
};
