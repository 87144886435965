import * as React from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import {callStatusMessage} from 'io/eniocaller/utils';
import {convertSecondsToTimer} from 'utils/time';
import {CALL_STATUS} from 'io/sip/constants';
import {Contact} from 'lucide-react';

export const CallInfo = ({callSession, timer, onEndCall, lastCustomNumber}) => {
	const isTimerVisible = React.useMemo(() => {
		if (!callSession) return false;
		return [
			CALL_STATUS.IN_CALL,
			CALL_STATUS.TERMINATING,
			CALL_STATUS.CALL_TERMINATED,
		].includes(callSession?.status);
	}, [callSession?.status]);

	const isCustomerInfoVisible = React.useMemo(() => {
		if (!callSession) return false;
		return (
			[
				CALL_STATUS.MEDIA_ADDED,
				CALL_STATUS.CALL_TERMINATED,
				CALL_STATUS.TERMINATING,
				CALL_STATUS.BUSY_HERE,
				CALL_STATUS.REQUEST_TERMINATED,
				CALL_STATUS.TEMPORARILY_UNAVAILABLE,
				CALL_STATUS.SESSION_PROGRESS,
				CALL_STATUS.IN_CALL,
				CALL_STATUS.RINGING,
			].includes(callSession?.status) && lastCustomNumber.length < 1
		);
	}, [callSession?.status, lastCustomNumber]);

	const customerInfo = React.useMemo(() => {
		if (!callSession) return null;
		return `${[
			callSession?.customerDetails?.client?.firstName,
			callSession?.customerDetails?.client?.lastName,
		]
			.filter(Boolean)
			.join(' ')} - ${[
			callSession?.customerDetails?.building?.address,
			callSession?.customerDetails?.building?.city,
		]
			.filter(Boolean)
			.join(', ')}`;
	}, [callSession?.customerDetails]);

	const isLastCustomNumberVisible = React.useMemo(() => {
		if (!callSession) return false;
		return (
			[
				CALL_STATUS.MEDIA_ADDED,
				CALL_STATUS.CALL_TERMINATED,
				CALL_STATUS.TERMINATING,
				CALL_STATUS.BUSY_HERE,
				CALL_STATUS.REQUEST_TERMINATED,
				CALL_STATUS.TEMPORARILY_UNAVAILABLE,
				CALL_STATUS.FORBIDDED,
				CALL_STATUS.RINGING,
			].includes(callSession?.status) && lastCustomNumber.length > 0
		);
	}, [callSession?.status, lastCustomNumber]);

	return (
		<div className="flex flex-col gap-2 text-xs px-4 py-3">
			<div>
				<div className="font-bold">
					<FormattedMessage id={callStatusMessage(callSession?.status)} />
				</div>
				{isTimerVisible && <div>{convertSecondsToTimer(timer)}</div>}
			</div>
			{isCustomerInfoVisible && (
				<div className="flex items-center gap-2 ">
					<Contact className="size-4 shrink-0" />
					<span>{customerInfo}</span>
				</div>
			)}
			{isLastCustomNumberVisible && <div>{lastCustomNumber}</div>}
			{callSession?.active && (
				<div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
					<button
						onClick={onEndCall}
						className="bg-red-500 text-white px-2 py-1 rounded-lg"
					>
						<FormattedMessage id="End call" />
					</button>
				</div>
			)}
		</div>
	);
};
