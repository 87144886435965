import React from 'react';
import {useQueryOrganizationIndex} from 'hooks/api/organization/useQueryOrganization';
import {Building} from 'lucide-react';

const OrganizationPicker = ({organizationId, onOrganizationIdChange}) => {
	const {data: organizationsData} = useQueryOrganizationIndex({});

	return (
		<div className="flex gap-3 items-center">
			<Building className="size-5 text-gray-400" />
			<select
				value={organizationId || ''}
				disabled
				onChange={e => onOrganizationIdChange(e.target.value || null)}
				className="border rounded-md h-8 px-2 font-medium shadow-sm disabled:bg-gray-200 disabled:shadow-none"
			>
				{(organizationsData?.data || []).map(organization => (
					<option key={organization.id} value={organization.id}>
						{organization.title}
					</option>
				))}
			</select>
		</div>
	);
};

export default OrganizationPicker;
