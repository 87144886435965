import React, {useState, useEffect} from 'react';
import {useAuth} from 'hooks/useAuth';
import {OrganizationBudgetForm} from './OrganizationBudgetForm';
import MonthPicker from './MonthPicker';
import OrganizationPicker from './OrganizationPicker';

export const OrganizationBudget = ({prevDate, onDateChange}) => {
	const {organizationId: userOrganizationId} = useAuth();
	const [organizationId, setOrganizationId] = useState(userOrganizationId);
	const [date, setDate] = useState(prevDate);

	useEffect(() => {
		if (date !== prevDate) {
			onDateChange(date);
		}
	}, [date, onDateChange, prevDate]);

	return (
		<div className="flex flex-col gap-6">
			<div className="flex justify-between items-start gap-8">
				<div className="flex flex-col gap-2 pl-4 border-r pr-8">
					<MonthPicker month={date} onMonthChange={setDate} />
					<OrganizationPicker
						organizationId={organizationId}
						onOrganizationIdChange={setOrganizationId}
					/>
				</div>
			</div>
			<OrganizationBudgetForm organizationId={organizationId} date={date} />
		</div>
	);
};
