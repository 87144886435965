import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'ramda';
import {FormattedMessage} from '@meiko/react-intl';
import {callStatusMessage} from 'io/eniocaller/utils';
import {callSession, deviceId, sipCallerExpanded, user} from 'modules/common/selectors';
import {
	toggleSipCallerExpanded,
	makeSipCall,
	markCallSessionTerminatedByUser,
} from 'modules/common/actions';
import {applyDispatch, applyState} from 'utils/redux';
import {convertSecondsToTimer} from 'utils/time';
import {isPilotUser} from 'utils/perms';
import {isValidPhoneNumber} from 'utils/phone-number';
import {CALL_STATUS} from 'io/sip/constants';
import getSipModule from 'io/sip';
import {CallInfo} from './CallInfo';
import {CallDialForm} from './CallDialForm';
import AudioTest from './AudioTest';
import VolumeControl from './VolumeControl';
import {ChevronDown, Phone, PhoneOff, PhoneCall, Settings} from 'lucide-react';
import clsx from 'clsx';

const EnioCallerClient = props => {
	const {
		callSession,
		toggleSipCallerExpanded,
		sipCallerExpanded,
		user,
		makeSipCall,
		markCallSessionTerminatedByUser,
		isMobile = false,
	} = props;

	const [callTimer, setTimer] = React.useState(
		callSession?.answerTime ? Math.floor(Date.now() / 1000) - callSession?.answerTime : 0,
	);

	const [isTestAudioVisible, setIsTestAudioVisible] = React.useState(false);
	const [customNumber, setCustomNumber] = React.useState('');
	const [lastCustomNumber, setLastCustomNumber] = React.useState('');

	const isPilot = user ? isPilotUser(user) : false;

	React.useEffect(() => {
		let timeoutId;
		if (callSession?.status === CALL_STATUS.RINGING && callTimer !== 0) {
			setTimer(0);
		}
		if (callSession?.status === CALL_STATUS.CALL_TERMINATED) {
			const callStopped = callSession?.endTime - callSession?.answerTime;
			if (callTimer !== callStopped && callTimer !== 0) {
				setTimer(callSession?.endTime - callSession?.answerTime);
			}
			clearTimeout(timeoutId);
			return;
		}
		if (callSession?.status === CALL_STATUS.IN_CALL) {
			timeoutId = setTimeout(() => {
				const currentTimestamp = Math.floor(Date.now() / 1000);
				setTimer(currentTimestamp - callSession?.answerTime);
			}, 1000);
		}
	}, [callSession, callTimer]);

	React.useEffect(() => {
		if (callSession.status === CALL_STATUS.TRYING && customNumber.length > 0) {
			setCustomNumber('');
		} else if (
			callSession.status === CALL_STATUS.TRYING &&
			customNumber.length < 1 &&
			lastCustomNumber.length > 0
		) {
			setCustomNumber('');
			setLastCustomNumber('');
		}
	}, [callSession]);

	const handleCallButtonClick = React.useCallback(() => {
		if (!callSession) {
			return;
		}
		if (callSession.active) {
			handleEndCall();
			return;
		}
		if (!isValidPhoneNumber(customNumber)) {
			return;
		}
		setLastCustomNumber(customNumber);
		makeSipCall({user: user, number: customNumber});
	}, [callSession, customNumber, user]);

	const handleEndCall = React.useCallback(() => {
		const sipModule = getSipModule({
			provider: user.defaultCall,
			isPilot,
		});
		sipModule.markCallNonActive();
		if (sipModule.canHangup()) {
			sipModule.hangup();
			markCallSessionTerminatedByUser();
		}
	}, [user]);

	const callButtonStatusClasses = React.useMemo(() => {
		// Show call icon as red if we are trying to connect OR we are in call
		// Different sip providers might have different statuses for call when call is connecting/ringing
		if (
			[
				CALL_STATUS.TRYING,
				CALL_STATUS.RINGING,
				CALL_STATUS.MEDIA_ADDED,
				CALL_STATUS.IN_CALL,
			].includes(callSession?.status)
		) {
			return 'bg-red-500 text-white';
		}

		return 'bg-green-600 text-white';
	}, [callSession?.status]);

	const customerName = React.useMemo(() =>
		[
			callSession?.customerDetails?.client?.firstName,
			callSession?.customerDetails?.client?.lastName,
		]
			.filter(Boolean)
			.join(' '),
	);

	return (
		<div
			className={clsx(
				'flex flex-row items-center gap-2 lg:w-[320px] w-full px-4 relative py-3',
				isMobile && 'border-y h-[60px]',
			)}
		>
			<div
				className={clsx(
					'flex-1 flex-shrink flex flex-row h-9 items-center gap-3 bg-white w-full text-gray-700 px-2',
					!isMobile && 'rounded-lg shadow-[0px_0px_0px_1px_rgba(0,0,0,0.1)]',
				)}
			>
				<button
					className={clsx(
						callButtonStatusClasses,
						'size-7 flex items-center justify-center rounded-full shrink-0',
					)}
					onClick={handleCallButtonClick}
				>
					{callSession?.status === CALL_STATUS.IN_CALL ? (
						<PhoneOff className="size-4" />
					) : [
							CALL_STATUS.TRYING,
							CALL_STATUS.RINGING,
							CALL_STATUS.OK,
							CALL_STATUS.MEDIA_ADDED,
					  ].includes(callSession?.status) ? (
						<PhoneCall className="size-4" />
					) : (
						<Phone className="size-4" />
					)}
				</button>
				{!callSession?.active ? (
					<div className="flex-1 flex text-xs font-bold truncate relative flex-shrink">
						<FormattedMessage id={callStatusMessage(callSession?.status)} />
					</div>
				) : (
					<div className="flex flex-col flex-1 text-xs gap-0.5">
						<div className="font-bold leading-none truncate">
							<FormattedMessage id={callStatusMessage(callSession?.status)} />
							{callSession?.status === CALL_STATUS.IN_CALL && (
								<span className="ml-1"> {convertSecondsToTimer(callTimer)}</span>
							)}
						</div>
						<div className="truncate leading-none">
							{callSession?.customerDetails?.client?.phone || lastCustomNumber}
							{customerName && ` (${customerName})`}
						</div>
					</div>
				)}
				<button
					onClick={() => toggleSipCallerExpanded()}
					className="size-8 hover:bg-gray-100 text-gray-500 flex items-center justify-center rounded-full shrink-0"
				>
					<ChevronDown
						className={clsx(
							`size-4 transition-transform`,
							isMobile ? 'rotate-180 size-6' : '',
							sipCallerExpanded && 'rotate-180',
						)}
					/>
				</button>
			</div>
			{sipCallerExpanded && (
				<div
					className={clsx(
						`flex divide-y divide-gray-200 absolute right-0 w-full min-w-[260px] lg:min-w-[320px] bg-white text-gray-700`,
						isMobile
							? 'bottom-full border-b flex-col-reverse'
							: 'top-full shadow-lg border rounded-b-lg flex-col',
					)}
				>
					{callSession?.active === false && (
						<CallDialForm
							onSubmit={handleCallButtonClick}
							value={customNumber}
							onChange={setCustomNumber}
						/>
					)}

					<CallInfo
						callSession={callSession}
						timer={callTimer}
						onEndCall={handleEndCall}
						lastCustomNumber={lastCustomNumber}
					/>

					<div className="flex flex-col gap-2 px-4 py-3">
						<a
							href="/#"
							className="text-gray-600 text-xs w-full flex items-center justify-between font-medium group"
							onClick={e => {
								e.preventDefault();
								setIsTestAudioVisible(isTestAudioVisible => !isTestAudioVisible);
							}}
						>
							<FormattedMessage id="Settings" />
							<div className="size-7 rounded-full flex text-gray-500 items-center justify-center group-hover:bg-gray-100">
								<Settings className="size-4" />
							</div>
						</a>

						{isTestAudioVisible && <VolumeControl />}

						{isPilot && <div>Beta</div>}
						{isTestAudioVisible && <AudioTest />}
					</div>
				</div>
			)}
		</div>
	);
};

export default compose(
	withRouter,
	connect(
		applyState({callSession, deviceId, sipCallerExpanded, user}),
		applyDispatch({
			toggleSipCallerExpanded,
			makeSipCall,
			markCallSessionTerminatedByUser,
		}),
	),
)(EnioCallerClient);
