import React, {useMemo} from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import {injectIntl, FormattedMessage, FormattedNumber} from '@meiko/react-intl';
import clsx from 'clsx';
import {useMutationTargetBatch} from 'hooks/api/target/useMutationTarget';
import {
	createBatchPayloadForWeekTargets,
	getWeekNumbersWithWorkingDays,
	currencyOptions,
} from './utils';
import {useAuth} from 'hooks/useAuth';
import {getYear, getWeek} from 'date-fns';
import {Loader2} from 'lucide-react';
import {useDeepCompareEffect} from 'react-use';
import useNotifications from 'hooks/useNotifications';

export const TeamTargetsForm = injectIntl(
	({teamId, date, users = [], targets = [], isPending, intl}) => {
		const {organizationId} = useAuth();

		const weekNumbers = useMemo(
			() => (date ? getWeekNumbersWithWorkingDays(new Date(date)) : []),
			[date],
		);

		const targetPred = (type, wn, uid) => t =>
			t.type === type && getWeek(new Date(t.startDate)) === wn && t.userId === uid;

		const defaultValues = useMemo(
			() => ({
				users: users.map(user => ({
					id: user.id,
					name: [user.firstName, user.lastName].filter(Boolean).join(' '),
					weeks: weekNumbers.map(wn => {
						const sales = targets.find(targetPred('dashboard-sales-sum', wn, user.id));
						const visits = targets.find(
							targetPred('dashboard-visits-count', wn, user.id),
						);
						const offers = targets.find(
							targetPred('dashboard-offers-count', wn, user.id),
						);

						return {
							weekNumber: wn,
							sales: {
								target: sales?.target || '',
								...(sales?.id && {id: sales.id}),
							},
							visits: {
								target: visits?.target || '',
								...(visits?.id && {id: visits.id}),
							},
							offers: {
								target: offers?.target || '',
								...(offers?.id && {id: offers.id}),
							},
						};
					}),
				})),
			}),
			[users, targets, weekNumbers],
		);

		const {control, register, handleSubmit, reset, watch} = useForm({defaultValues});

		const watchedFields = watch();

		const {fields: userFields} = useFieldArray({
			control,
			name: 'users',
		});

		// reset form when defaultValues change
		useDeepCompareEffect(
			() => reset({users: defaultValues.users}),
			[defaultValues.users],
		);

		const {mutateAsync, isLoading} = useMutationTargetBatch();

		const year = useMemo(() => getYear(date ? new Date(date) : new Date()), [date]);

		const calculatedWeekTargets = useMemo(
			() =>
				weekNumbers.map(wn => ({
					weekNumber: wn,
					sales: watchedFields.users.reduce((acc, user) => {
						const target =
							user.weeks.find(week => week.weekNumber === wn)?.sales.target || 0;
						return acc + parseInt(target);
					}, 0),
				})),
			[weekNumbers, watchedFields],
		);

		const {openNotification} = useNotifications();
		const onSubmit = async formData => {
			try {
				await mutateAsync(
					createBatchPayloadForWeekTargets({
						formData,
						organizationId,
						teamId,
						year,
					}),
				);
			} catch (error) {
				openNotification({
					message: intl.messages['Error submitting targets'],
					type: 'error',
				});
			}
		};

		const gridClasses = clsx('grid divide-x', {
			'grid-cols-[1fr_1fr_repeat(5,minmax(0,1fr))]': weekNumbers.length === 5,
			'grid-cols-[1fr_1fr_repeat(4,minmax(0,1fr))]': weekNumbers.length === 4,
		});

		const inputClasses =
			'h-8 px-2 text-sm w-full text-center rounded-none focus:outline-none appearance-none';

		return (
			<form onSubmit={handleSubmit(onSubmit)} className="relative">
				{isPending && (
					<div className="absolute inset-0 bg-white/60 backdrop-blur-[1px] z-10 rounded-xl" />
				)}
				<div className="flex flex-col divide-y-2 divide-gray-300 rounded-xl border overflow-hidden">
					<div className={clsx(gridClasses, 'bg-gray-50 text-gray-600 text-xs')}>
						<div className="col-span-2 py-2 px-4">
							<FormattedMessage id="User" />
						</div>
						{weekNumbers.map(wn => (
							<div key={wn} className="text-center py-2">
								<FormattedMessage id="Week {weekNumber}" values={{weekNumber: wn}} />
							</div>
						))}
					</div>
					{!teamId ? (
						<div className="bg-gray-50 p-8 text-center text-sm text-gray-500">
							<FormattedMessage id="Choose team to edit targets" />
						</div>
					) : (
						userFields?.map((user, ui) => (
							<div key={ui} className={clsx(gridClasses, 'group')}>
								<div className="font-medium text-sm px-4 py-2.5 text-gray-800">
									{user.name}
								</div>
								<div className="flex flex-col divide-y opacity-0 group-hover:opacity-100 group-focus-within:opacity-100">
									<span className="flex items-center justify-end h-8 text-gray-500 text-xs px-3">
										<FormattedMessage id="Sales ({unit})" values={{unit: '€'}} />
									</span>
									<span className="flex items-center justify-end h-8 text-gray-500 text-xs px-3">
										<FormattedMessage id="Visits (pcs.)" />
									</span>
									<span className="flex items-center justify-end h-8 text-gray-500 text-xs px-3">
										<FormattedMessage id="Offers (pcs.)" />
									</span>
								</div>
								{user.weeks.map(({visits, sales, offers}, wi) => (
									<div key={wi} className="flex flex-col divide-y">
										<input
											{...register(`users.${ui}.weeks.${wi}.sales.target`)}
											type="number"
											step={1000}
											defaultValue={sales}
											className={inputClasses}
											onFocus={e => e.target.select()}
										/>
										<input
											{...register(`users.${ui}.weeks.${wi}.visits.target`)}
											type="number"
											defaultValue={visits}
											className={inputClasses}
											onFocus={e => e.target.select()}
										/>
										<input
											{...register(`users.${ui}.weeks.${wi}.offers.target`)}
											type="number"
											defaultValue={offers}
											className={inputClasses}
											onFocus={e => e.target.select()}
										/>
									</div>
								))}
							</div>
						))
					)}
					<div className={clsx(gridClasses)}>
						<div className="col-span-2 flex items-center justify-end py-2 px-4 text-xs text-gray-500">
							<FormattedMessage id="Calculated sales targets" />
						</div>
						{calculatedWeekTargets.map(({sales}, wn) => (
							<div key={wn} className="text-center py-2 text-xs">
								<span className="text-teal-600 inline-flex font-medium bg-teal-50 px-2 py-1 rounded-lg">
									<FormattedNumber value={sales} {...currencyOptions} />
								</span>
							</div>
						))}
					</div>
				</div>
				<div className="flex justify-end items-center mt-6">
					<button
						type="submit"
						disabled={isLoading || isPending}
						className="bg-green-600 text-white font-medium text-sm h-10 px-4 rounded-lg disabled:opacity-50"
					>
						{isLoading ? (
							<Loader2 className="w-4 h-4 animate-spin" />
						) : (
							<FormattedMessage id="Save" />
						)}
					</button>
				</div>
			</form>
		);
	},
);
