import {useApi} from 'hooks/api/useApi';

export const apiResourceName = 'team';
export const apiPath = '/teams';

export const useFetchTeam = () => {
	const {get} = useApi();

	const index = ({
		teamIds = [],
		getAllTeams = true,
		customerOrganizationTeams = false,
		type = '',
	}) =>
		get(apiPath, {
			params: {
				getAllTeams,
				customerOrganizationTeams,
				teamIds,
				type,
				_limit: 999,
			},
		});

	const byId = ({teamId}) => get(`${apiPath}/${teamId}`);

	return {
		index,
		byId,
	};
};
