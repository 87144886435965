// core dependencies
import React, {useEffect} from 'react';
import {reduxForm, reset, formValueSelector} from 'redux-form';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'ramda';
import styled from 'styled-components';
// custom helpers & utils
import {applyState} from 'utils/redux';
import {injectIntl, FormattedMessage} from '@meiko/react-intl';
import * as buildingDicts from 'dicts/buildings';
import {emptyBuildingsFilters} from 'modules/buildingsApp/buildingsPage/constants';
import {handleFormSubmitFail} from 'fragments/helpers';
// components
import Field from 'components/generic/Field';
import Input from 'components/generic/Input';
import Row from 'components/generic/Row';
import Col from 'components/generic/Col';
import FormField from 'components/generic/FormField';
import FormBlock from 'components/generic/FormBlock';
import Label from 'components/generic/Label';
import FieldError from 'components/generic/FieldError';
import ContentSeparator from 'components/generic/ContentSeparator';
import Button from 'components/generic/Button';
import AreasSelector from './AreasSelector';
import TagIdsFormlet from 'components/views/TagIdsFormlet';
import CsvFileSelector from './CsvFileSelector';
import ReactSelect from 'components/generic/ReactSelect';
import {buildingSpecificTypesByType} from 'modules/buildingsApp/buildingsPage/utils';
import {buildingsType} from 'modules/buildingsApp/buildingsPage/selectors';
import BuildingEncounterStateSelectFormlet from 'components/generic/BuildingEncounterStateSelectFormlet';
import LatestEncounterReasonMappingFormlet from 'components/views/LatestEncounterReasonMappingFormlet';
import SpacedItems from 'components/generic/SpacedItems';
import ProductFormlet from 'components/views/ProductFormlet';
import ProductStateFormlet from 'components/views/ProductStateFormlet';

const Scrollable = styled.div`
	margin-right: -20px;
	padding-right: 20px;
	padding-bottom: 5px;

	& > :first-child {
		margin-top: 0;
	}
`;

const Sticky = styled.div`
	position: sticky;
	bottom: 0;
	background: #fff;
	padding-bottom: 20px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
`;

const StyledSeparator = styled(ContentSeparator)`
	margin: 0 -20px 20px -20px;
`;

export const BUILDINGS_FILTER_FORM = 'buildingsFilterForm';
const fieldValue = path => state => formValueSelector(BUILDINGS_FILTER_FORM)(state, path);

const BuildingsFilterForm = ({
	intl,
	id,
	handleSubmit,
	disabled,
	clearQuery,
	areasSelectorOpen,
	areasSelectorSearch,
	openAreasSelector,
	closeAreasSelector,
	setAreasSelectorType,
	setAreasSelectorSearch,
	openAreasSelectorSuggestion,
	dispatch,
	change,
	onSubmitClick = () => {},
	availableTags = [],
	getAvailableTags = () => null,
	tagIdsValue = '',
	csvFileSelectorOpen,
	csvFileSelectorProcessing,
	openCsvFileSelector,
	closeCsvFileSelector,
	csvFileSelectorUpload,
	csvFileIdValue,
	buildingsType,
	latestEncounterTypeValue,
	latestEncounterStateValue,
	reasonMappings,
	areasValue,
	drawnPolygon,
	searchProducts,
}) => {
	useEffect(() => {
		if (areasValue && areasValue.length === 0) {
			change('latestEncounterType', '');
			change('latestEncounterState', '');
			change('latestEncounterReasonMappingId', '');
		}
	}, [areasValue]);
	return (
		<form id={id} onSubmit={handleSubmit} autoComplete="off">
			<Scrollable>
				<Field
					name="types"
					format={value => {
						return (value ?? [])
							.filter(v => !!v)
							.map(value => ({
								value,
								label: intl.formatMessage({id: buildingDicts.type[value]}),
							}));
					}}
					parse={value => {
						return [...new Set(value.map(v => (v.value ? v.value : v)))];
					}}
					props={{
						defaultOptions: buildingSpecificTypesByType[buildingsType].map(v => ({
							value: v,
							label: intl.formatMessage({id: buildingDicts.type[v]}),
						})),
					}}
					component={({input, inputId, meta, defaultOptions}) => (
						<FormBlock>
							<Label htmlFor={inputId}>
								<FormattedMessage id="Type" />
							</Label>
							<ReactSelect
								{...input}
								minWidth="150px"
								id={inputId}
								async
								block
								isMulti
								defaultOptions={defaultOptions}
								closeMenuOnSelect={false}
								hideSelectedOptions={false}
								concise
							/>
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<BuildingEncounterStateSelectFormlet name="encounterState" isMulti={true} />

				<Field
					name="dateFrom"
					type="date"
					component={({input, inputId, meta, state}) => (
						<FormBlock>
							<Label htmlFor={inputId} noWrap>
								<FormattedMessage id="Status changed from [date]" />
								&nbsp;
							</Label>
							<Input {...input} id={inputId} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>
				<Field
					name="dateTo"
					type="date"
					component={({input, inputId, meta, state}) => (
						<FormBlock>
							<Label htmlFor={inputId} noWrap>
								<FormattedMessage id="Status changed to [date]" />
								&nbsp;
							</Label>
							<Input {...input} id={inputId} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>

				<Row>
					<Col>
						<ProductFormlet
							name="products"
							isMulti={true}
							searchProducts={searchProducts}
						/>
						<ProductStateFormlet name="buildingProductStateEncounterStates" isClearable />
					</Col>
					<Col size={50}>
						<Field
							name="manufacturingYearStart"
							type="number"
							component={({input, inputId, meta, state}) => (
								<FormField block>
									<Label htmlFor={inputId} noWrap>
										<FormattedMessage id="Manufacturing year" />
										&nbsp;
									</Label>
									<Input
										{...input}
										id={inputId}
										block
										stretch
										placeholder={intl.formatMessage({id: 'From [date from]'})}
									/>
									<FieldError {...meta} />
								</FormField>
							)}
						/>
					</Col>
					<Col size={50}>
						<Field
							name="manufacturingYearEnd"
							type="number"
							component={({input, inputId, meta, state}) => (
								<FormField block>
									<Label htmlFor={inputId} noWrap>
										&nbsp;
									</Label>
									<Input
										{...input}
										id={inputId}
										block
										stretch
										placeholder={intl.formatMessage({id: 'To [date to]'})}
									/>
									<FieldError {...meta} />
								</FormField>
							)}
						/>
					</Col>
				</Row>
				<Field
					name="unknownManufacturingYear"
					type="checkbox"
					component={({input, inputId}) => (
						<FormBlock>
							<Input {...input} id={inputId} />
							<Label htmlFor={inputId}>
								&nbsp;
								<FormattedMessage id="Unknown manufacturing year" />
							</Label>
						</FormBlock>
					)}
				/>
				<Field
					name="onlyWithClientWithPhone"
					type="checkbox"
					component={({input, inputId}) => (
						<FormBlock>
							<Input {...input} id={inputId} />
							<Label htmlFor={inputId}>
								&nbsp;
								<FormattedMessage id="In call list (name & phone)" />
							</Label>
						</FormBlock>
					)}
				/>
				<Field
					name="onlyUnknownBuildings"
					type="checkbox"
					component={({input, inputId}) => (
						<FormBlock noWrap>
							<Input {...input} id={inputId} />
							<Label htmlFor={inputId}>
								&nbsp;
								<FormattedMessage id="Show only unknown building types" />
							</Label>
						</FormBlock>
					)}
				/>
				<TagIdsFormlet
					availableTags={availableTags}
					getAvailableTags={getAvailableTags}
					currentValue={tagIdsValue}
				/>
				<Field
					name="zip"
					type="number"
					component={({input, inputId, meta, state}) => (
						<FormBlock>
							<Label htmlFor={inputId} noWrap>
								<FormattedMessage id="ZIP code" />
								&nbsp;
							</Label>
							<Input {...input} id={inputId} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>
				<Field
					name="city"
					type="text"
					component={({input, inputId, meta, state}) => (
						<FormBlock>
							<Label htmlFor={inputId} noWrap>
								<FormattedMessage id="Town" />
								&nbsp;
							</Label>
							<Input {...input} id={inputId} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>
				<Field
					name="municipalName"
					type="text"
					component={({input, inputId, meta, state}) => (
						<FormBlock>
							<Label htmlFor={inputId} noWrap>
								<FormattedMessage id="Municipality" />
								&nbsp;
							</Label>
							<Input {...input} id={inputId} block stretch />
							<FieldError {...meta} />
						</FormBlock>
					)}
				/>
				<Field
					name="areas"
					props={{
						areasSelectorOpen,
						areasSelectorSearch,
						openAreasSelector,
						closeAreasSelector,
						setAreasSelectorType,
						setAreasSelectorSearch,
						openAreasSelectorSuggestion,
						clearAreasSelection: () => {
							change('areas', emptyBuildingsFilters.areas);
						},
						disabled,
					}}
					component={AreasSelector}
				/>

				<Field
					name="drawnPolygon"
					props={{drawnPolygon}}
					component={({inputId, drawnPolygon}) => (
						<FormBlock>
							{drawnPolygon && (
								<>
									<Label htmlFor={inputId} noWrap>
										<FormattedMessage id="Drawn polygon" />
										&nbsp;
									</Label>
									<SpacedItems>
										<div />
										<Button onClick={() => change('drawnPolygon', null)}>
											<FormattedMessage id="Clear" />
										</Button>
									</SpacedItems>
								</>
							)}
						</FormBlock>
					)}
				/>

				<LatestEncounterReasonMappingFormlet
					disabled={!areasValue || !areasValue.length}
					change={change}
					reasonMappings={reasonMappings}
					latestEncounterTypeValue={latestEncounterTypeValue}
					latestEncounterStateValue={latestEncounterStateValue}
				/>

				<Field
					name="csvFileId"
					type="hidden"
					props={{
						csvFileValue: csvFileIdValue,
						csvFileSelectorOpen,
						csvFileSelectorProcessing,
						openCsvFileSelector,
						closeCsvFileSelector,
						csvFileSelectorUpload,
						clearCsvFileSelection: () => {
							change('uniqId', null);
						},
						onSubmit: values => {
							csvFileSelectorUpload(values);
						},
					}}
					component={CsvFileSelector}
				/>
			</Scrollable>
			<Sticky>
				<StyledSeparator />
				<ButtonWrapper>
					<Button
						onClick={() => onSubmitClick()}
						appearance="success"
						type="submit"
						form={id}
						disabled={disabled}
					>
						<i className="fa fa-search pad-right" />
						<FormattedMessage id="Search" />
					</Button>
					<Button
						onClick={() => {
							dispatch(reset(BUILDINGS_FILTER_FORM));
							clearQuery();
						}}
						disabled={disabled}
					>
						<FormattedMessage id="Clear search criteria" />
					</Button>
				</ButtonWrapper>
			</Sticky>
		</form>
	);
};

BuildingsFilterForm.propTypes = {
	intl: propTypes.object.isRequired,
	id: propTypes.any,
	handleSubmit: propTypes.func,
	onSubmit: propTypes.func,
	disabled: propTypes.bool,
	clearQuery: propTypes.func,
	areasSelectorOpen: propTypes.bool,
	areasSelectorSearch: propTypes.string,
	openAreasSelector: propTypes.func,
	closeAreasSelector: propTypes.func,
	setAreasSelectorType: propTypes.func,
	setAreasSelectorSearch: propTypes.func,
	openAreasSelectorSuggestion: propTypes.func,
	dispatch: propTypes.func,
	onSubmitClick: propTypes.func,
	csvFileSelectorOpen: propTypes.bool,
	csvFileSelectorProcessing: propTypes.bool,
	openCsvFileSelector: propTypes.func,
	closeCsvFileSelector: propTypes.func,
	csvFileSelectorUpload: propTypes.func,
};

export default compose(
	injectIntl,
	connect(
		applyState({
			tagIdsValue: fieldValue('tagIds'),
			csvFileIdValue: fieldValue('uniqId'),
			latestEncounterTypeValue: fieldValue('latestEncounterType'),
			latestEncounterStateValue: fieldValue('latestEncounterState'),
			areasValue: fieldValue('areas'),
			drawnPolygon: fieldValue('drawnPolygon'),
			buildingsType,
		}),
	),
	reduxForm({
		form: BUILDINGS_FILTER_FORM,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: handleFormSubmitFail,
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true,
	}),
)(BuildingsFilterForm);
