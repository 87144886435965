import {FormattedMessage, injectIntl} from '@meiko/react-intl';
import Button from 'components/generic/Button';
import ButtonGroup from 'components/generic/ButtonGroup';
import Collapse from 'components/generic/Collapse';
import DateFormlet from 'components/views/DateFormlet';
import TeamsFormlet from 'components/views/TeamsFormlet';
import UsersFormlet from 'components/views/UsersFormlet';
import useWindowWidth from 'hooks/windowWidth';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {formValueSelector, reduxForm} from 'redux-form';
import styled from 'styled-components';
import {screenMdMin, screenMdMinValue} from 'styles/constants';
import {RANGE_OPTIONS, rangeToDates} from 'utils/formData';
import {applyState} from 'utils/redux';
import services from 'services';
import messages from 'dicts/messages';
import OrganizationsFormlet from 'components/views/OrganizationsFormlet';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const GridRow = styled.div`
	display: grid;
	gap: 10px;
	margin-top: 10px;
	> div {
		padding: 0px;
		margin: 0px;
	}
	@media screen and (min-width: ${screenMdMin}) {
		grid-template-columns: repeat(5, 1fr);
	}
`;
const Form = styled.form`
	margin-top: 20px;
	@media screen and (min-width: ${screenMdMin}) {
		margin-top: 0px;
	}
`;

const ButtonAndSubmitContainer = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 10px;
	@media screen and (min-width: ${screenMdMin}) {
		flex-direction: row;
	}
`;

const fieldOrganizationId = 'organizationId';
const fieldTeamId = 'teamId';
const fieldUserId = 'userId';
const fieldStartsAt = 'startsAt';
const fieldEndsAt = 'endsAt';

const validate = (values, props) => {
	const {canClearTeam} = props;

	const errors = {};

	if (!values?.startsAt) {
		errors.startsAt = intl.formatMessage({id: messages.invalidDateRange});
	}

	if (!values?.endsAt) {
		errors.endsAt = intl.formatMessage({id: messages.invalidDateRange});
	}

	if (!values.organizationId) {
		errors.organizationId = intl.formatMessage({id: messages.requiredField});
	}

	const teamIdValue = values.teamId ?? [];
	if (teamIdValue.length === 0 && !canClearTeam) {
		errors.teamId = intl.formatMessage({id: messages.requiredField});
	}
	return errors;
};

const DashboardFilterForm = ({
	intl,
	change,
	teams,
	organizations,
	teamsValue,
	userValue,
	canChangeUser,
	canChangeTeam,
	canChangeOrganization,
	handleSubmit,
}) => {
	const {width: windowWidth} = useWindowWidth();

	const [isVisible, setIsVisible] = useState(false);
	const setDateRange = range => {
		const [start, end] = rangeToDates(range);
		change(fieldStartsAt, start);
		change(fieldEndsAt, end);
	};

	const availableTeams = useMemo(
		() =>
			teams.filter(t => {
				const checks = [true]; // by default, everything is allowd

				if ((teamsValue ?? []).length > 0) {
					checks.push(teamsValue.includes(t.id));
				}

				return checks.every(truthy => truthy);
			}),
		[(teamsValue ?? []).length, teams.length ?? []],
	);

	const usersFromTeams = useMemo(
		() =>
			(teamsValue ?? []).length === 0
				? availableTeams
				: availableTeams.filter(t => teamsValue.includes(t.id)),
		[availableTeams],
	);
	// NOTE: if teams are selected, those should be filtered against
	const availableUsersById = usersFromTeams.reduce((acc, cur) => {
		const teamUsers = cur.users.forEach(u => {
			acc[u.id] = u;
		});
		return {
			...acc,
			...teamUsers,
		};
	}, {});

	/**
	 * Update user value in case available users change (i.e filters out selected users which do not belong to chosen teams)
	 * This could be also done on submit but this way we immediately 'clean' the form
	 */
	useEffect(() => {
		if (Object.keys(availableUsersById).length > 0 && (userValue ?? []).length > 0) {
			change(
				fieldUserId,
				userValue.filter(id => availableUsersById[id]),
			);
		}
	}, [availableUsersById, userValue]);

	const shouldBeBehindToggle = windowWidth < screenMdMinValue;

	return (
		<Collapse
			showToggle={shouldBeBehindToggle}
			isVisible={shouldBeBehindToggle ? isVisible : true}
			onToggle={value => setIsVisible(value)}
			title={intl.formatMessage({id: 'Filter'})}
		>
			<Form onSubmit={handleSubmit}>
				<ButtonAndSubmitContainer>
					<ButtonGroup>
						{RANGE_OPTIONS.map(({label, value}) => (
							<Button key={value} onClick={() => setDateRange(value)}>
								<FormattedMessage id={label} />
							</Button>
						))}
					</ButtonGroup>

					<Button appearance="success" type="submit">
						<FormattedMessage id="Search" />
					</Button>
				</ButtonAndSubmitContainer>

				<GridRow>
					<DateFormlet
						label={<FormattedMessage id="Starts at" />}
						name={fieldStartsAt}
						isClearable={false}
					/>
					<DateFormlet
						label={<FormattedMessage id="Ends at" />}
						name={fieldEndsAt}
						isClearable={false}
					/>
					<OrganizationsFormlet
						onChange={e => {
							change('teamId', []);
							change('userId', []);
						}}
						disabled={!canChangeOrganization}
						name={fieldOrganizationId}
						organizations={organizations}
						required={true}
					/>
					<TeamsFormlet
						disabled={!canChangeTeam}
						onChange={() => {
							change('userId', []);
						}}
						name={fieldTeamId}
						teams={teams}
					/>
					<UsersFormlet
						disabled={!canChangeUser}
						name={fieldUserId}
						users={Object.values(availableUsersById)}
					/>
				</GridRow>
			</Form>
		</Collapse>
	);
};

const DASHBOARD_FILTER_FORM = 'dashboard-filter-form';
const fieldValue =
	(field, ...rest) =>
	state =>
		formValueSelector(DASHBOARD_FILTER_FORM)(state, field, ...rest);

export default compose(
	injectIntl,
	connect(
		applyState({
			teamsValue: fieldValue(fieldTeamId),
			userValue: fieldValue(fieldUserId),
		}),
	),
	reduxForm({
		form: DASHBOARD_FILTER_FORM,
		validate,
	}),
)(DashboardFilterForm);
