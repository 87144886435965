import {getDay, getWeek, eachDayOfInterval, startOfMonth, endOfMonth} from 'date-fns';
import {fi} from 'date-fns/locale';
import {currency} from 'constants/loc';

export const currencyOptions = {
	style: 'currency',
	currency,
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
};

// create batch payload for budgets
export const createBatchPayloadForBudgets = ({formData, organizationId, year, month}) => {
	const targetBase = {
		organizationId,
		year,
		month,
		type: 'dashboard-sales-budget-sum',
		timespan: 'month',
	};

	return [
		...formData.organizations
			.filter(data => !!data.budget)
			.map(data => ({
				...targetBase,
				...(data.id && {id: data.id}),
				target: data.budget,
			})),
		...formData.teams
			.filter(data => !!data.budget)
			.map(data => ({
				...targetBase,
				teamId: data.teamId,
				...(data.id && {id: data.id}),
				target: data.budget,
			})),
	];
};

// create batch payload for week targets
export const createBatchPayloadForWeekTargets = ({
	formData,
	organizationId,
	teamId,
	year,
}) =>
	formData.users
		.reduce(
			(acc, user) => [
				...acc,
				...user.weeks.map(({weekNumber, sales, offers, visits}) => {
					const targetBase = {
						organizationId,
						userId: user.id,
						teamId,
						timespan: 'week',
						week: weekNumber,
						year,
					};

					return [
						{
							...targetBase,
							type: 'dashboard-sales-sum',
							...(sales.id && {id: sales.id}),
							target: sales.target,
						},
						{
							...targetBase,
							type: 'dashboard-offers-count',
							...(offers.id && {id: offers.id}),
							target: offers.target,
						},
						{
							...targetBase,
							type: 'dashboard-visits-count',
							...(visits.id && {id: visits.id}),
							target: visits.target,
						},
					];
				}),
			],
			[],
		)
		.flat()
		.filter(({target}) => !!target);

// get week numbers with working days
export const getWeekNumbersWithWorkingDays = date => {
	const start = startOfMonth(date);
	const end = endOfMonth(date);
	const allDays = eachDayOfInterval({start, end});
	const weeks = new Set();

	allDays.forEach(day => {
		const weekNumber = getWeek(day, {locale: fi});
		const dayOfWeek = getDay(day);
		if (dayOfWeek >= 1 && dayOfWeek <= 5) {
			weeks.add(weekNumber);
		}
	});

	return Array.from(weeks);
};
